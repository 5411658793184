.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoList {
  list-style-type: none;
}

.mainContainer{
 max-width: 955px;
 display: flex;
 flex-direction: row;
 padding: 25px;
}

.mainColumn-1 {
  flex-shrink: 0;
  flex-basis: 50%;
  margin-top: 25px;
}

.mainColumn-2{
  padding: 10px;
}

.orange {
  color:#e68a39;
}

.blue {
  color: #4cbee6;
}

.gray {
  color: #767676;
}

.padding-5 {
  padding: 5px;
}

.padding-15 {
  padding: 15px;
}

.headerContainer {
  height: 80px;
  background-color: #4cbee6;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 5px 0;
}

.cazvidLogo {
  margin: auto;
}

.cazvidTagline{
font-size: 1rem;
color: #fff;
margin: 0 auto;
letter-spacing: 2px;
}

.pageLoader {
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;
}

.subHeaderContainer {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  padding: 10px 0;
  background: #fafafa;
  border-bottom: 1px solid rgba(204,204,204,.41);
}
.subHeaderListingTitle {
  color: rgb(131, 131, 131);
  font-size: 34px;
  font-weight: 500;
  margin: 0px;
}

.subHeaderListingLocation {
  color: rgb(160, 160, 160);
  font-weight: 400;
  margin: 5px;
}

.postContainer {
  padding: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  margin-top: 24px;
  width: 80%;

}

.videoContainer {
  overflow: hidden;
  flex-direction: row;
  justify-content:center;
  align-items:center;
}
.videoStyle {
  border-radius: 15px;
  width: 340px;
  height: 600px;
}

.column1Container {
  flex-shrink: 0;
  min-width: 350px;
  width: 33%;
  padding: 0px;
  justify-content:center;
  align-items:center;
}

.column2Container {
  padding: 0px 25px;
}

.detailsContainer {
  text-align: left;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleContainer {
  margin: 0px;
  padding: 0px;
}

.listingTitle {
  color: rgb(82, 82, 82);
  margin: 5px 0;
  font-size: 1.4em;
}

.listingLocation {
  color: rgb(134, 134, 134);
  margin: 5px 6px;
  font-size: 1em;
}

.listingUserName {
  font-size: 1em;
  color: rgb(134, 134, 134);
  margin: 5px 6px;
  font-size: 1em;
}

.listingDescription {
  color: rgb(82, 82, 82);
  font-size: 1.1em;
  line-height: 1.5em;
}

.promoContainer {
  border-color: #f89e4c;
  border-width: 1px;
  border-style: solid;
  margin-top: 24px;
  border-radius: 15px;
  padding: 5px 24px 24px 24px;
  margin-bottom: 24px;
  background-color: #eeeeee;
}

.promoTitle {
  color: #f89e4c;
  font-size: 1.3em;
  margin: 5px 0;
}

.promoSubTitle {
  color: #767676;
  font-size: 1em;
  margin: 5px 0;
}

.promoDescription {
  color:#767676;
  font-size: 1em;
}

.promoSubTitle2 {
  font-size: 1em;
}

.promoColumn {
  display:flex;
  flex-direction: row;
  flex: 0 1 auto;
  gap: 10px;
}

.promoColumn-1 {

  flex-basis: 50%;
  flex-grow: 0;
  padding: 3px;
  border: 1px solid #C5C5C5;
  border-radius: 12px 12px 12px 12px;
}

.promoColumn-2{
  flex-basis: 50%;
  flex-grow: 0;
  padding: 3px;
  width: 100%;
  border: 1px solid #C5C5C5;
  border-radius: 12px 12px 12px 12px;
}

ul {
  list-style-type: circle;
  text-align: left;
}

ul li {
  margin: 5px;
}

.fa-solid, .fas {
  color: #959595;
}

.buttonContainer {
  align-items: center;
  display: flex;
}

.buttonApply {
	-moz-box-shadow:inset 0px 50px 4px -23px #f5ae70;
	-webkit-box-shadow:inset 0px 50px 4px -23px #e68a39;
	box-shadow:inset 0px 50px 4px -23px #f5ae70;
	background-color:#f89f4c;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	border:1px solid #f89f4c;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:18px;
	font-weight:bold;
	padding:10px 36px;
	text-decoration:none;
	text-shadow:0px 1px 0px #c96f20;
  width: 100%;
}
.buttonApply:hover {
	background-color:#f0974f;
}
.buttonApply:active {
	position:relative;
	top:1px;
}

.buttonText {
  font-size: 1rem;
  margin: 0px;
}

.buttonSubText {
  font-size: 0.75rem;
  margin: 0px;
  text-shadow:none;
}


@media only screen and (max-width: 995px) {

  .mainContainer {
    width: 90%;
    flex-direction: column-reverse;
  }
  
  .mainColumn-1 {
    width: 100%;
    justify-content:center;
    align-items:center;
    margin-top: 25px;
  }

  .mainColumn-2 {
    margin: 0 0 0rem;
  }
  .postContainer {
    width: 90%;
    flex-direction: column;
  }
  
  .column1Container {
    width: 100%;
    justify-content:center;
    align-items:center;
  }

  .column2Container {
    margin: 0 0 0rem;
  }

  .detailsContainer {
    padding: 25px 15px 0px 015px;
  }

  .listingTitle {
    font-size: 1.5em;
    text-align: center;
  }

  .promoColumn {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
  }

  .promoColumn-1 {
    flex-shrink: 0;
    width: 100%;
  }
  
  .promoColumn-2{
    width: 100%;
  }

  .promoTitle {
    color: #f89e4c;
    font-size: 1.1em;
    margin: 5px 0;
  }
  
  .promoSubTitle {
    color: #767676;
    font-size: 1em;
    margin: 5px 0;
  }
  
  .promoDescription {
    color:#767676;
    font-size: 1em;
    text-align: left;
  }
}
