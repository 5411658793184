.promoColumn-1 {
  flex-basis: 100% !important;
  flex-grow: 0;
  padding: 3px;
  margin-bottom: 25px;
  border: 1px solid #C5C5C5;
  border-radius: 12px 12px 12px 12px;
}

.buttonContainer {
  align-items: center;
  display: flex;
}

.buttonApply {
	-moz-box-shadow:inset 0px 50px 4px -23px #f5ae70;
	-webkit-box-shadow:inset 0px 50px 4px -23px #e68a39;
	box-shadow:inset 0px 50px 4px -23px #f5ae70;
	background-color:#f89f4c;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	border:1px solid #f89f4c;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:18px;
	font-weight:bold;
	padding:10px 36px;
	text-decoration:none;
	text-shadow:0px 1px 0px #c96f20;
  width: 100%;
}
.buttonApply:hover {
	background-color:#f0974f;
}
.buttonApply:active {
	position:relative;
	top:1px;
}

.buttonText {
  font-size: 1rem;
  margin: 0px;
}

.buttonSubText {
  font-size: 0.75rem;
  margin: 0px;
  text-shadow:none;
}

@media only screen and (max-width: 995px) {
	.promoColumn-1 {
		flex-basis: 80% !important;
	  }
}