.videoContainer {
  overflow: hidden;
  flex-direction: row;
  justify-content:center;
  align-items:center;
}

.videoStyle {
  border-radius: 15px;
  width: 340px;
  height: 600px;
}