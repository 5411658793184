.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainContainer{
 max-width: 955px;
 display: flex;
 flex-direction: row;
 padding: 25px;
}

@media only screen and (max-width: 995px) {
  .mainContainer {
    width: 90%;
    flex-direction: column-reverse;
  }
}
