.subHeaderContainer {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  padding: 10px 0;
  background: #fafafa;
  border-bottom: 1px solid rgba(204,204,204,.41);
}

.titleContainer {
  margin: 0px;
  padding: 0px;
}

.subHeaderListingTitle {
  color: rgb(131, 131, 131);
  font-size: 34px;
  font-weight: 500;
  margin: 0px;
}

.subHeaderListingLocation {
  color: rgb(160, 160, 160);
  font-weight: 400;
  margin: 5px;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fa-solid, .fas {
  color: #959595;
}